import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../_services/authentication.service';
import { UserService } from '../_services/user.service';
import { StorageService } from '../_services/storage.service';
import { AlertService } from '../_services/alert.services';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  sports_name: string = '';
  forgot_key: string = '';
  activation_key: string = '';

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private storage: StorageService,
    private userService: UserService,
    private alertService: AlertService,
  ) {
    // redirect to lobby if already logged in
    if (this.authenticationService.currentUserValue) {
        this.router.navigate(['/lobby']);

    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.forgot_key = params['key'];
    });
    this.route.queryParams.subscribe(params => {
      this.activation_key = params['activation_key'];
    });

    if (this.forgot_key) {
      this.userService.forgotPasswordValidate({key: this.forgot_key})
        .pipe(first())
        .subscribe(
          data => {
            this.router.navigate(['/reset-password'], { queryParams: { key: this.forgot_key } });
          },
          error => {
            this.alertService.error(error.key ? error.key : 'Invalid Request!');
            this.router.navigate(['/reset-password'], { queryParams: { key: this.forgot_key } });
          });
    }

    if (this.activation_key) {
      this.userService.activateAccount({key: this.activation_key})
        .pipe(first())
        .subscribe(
          data => {
            this.alertService.error(data.message ? data.message : 'Account Activated Successfully!');
          },
          error => {
            this.alertService.error(error.key ? error.key : 'Invalid Request!');
          });
    }

  }

}


