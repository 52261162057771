import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NotificationComponent } from './notification.component';

import { NotificationRoutingModule } from './notification-routing.module';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        NotificationRoutingModule,
        TranslateModule,
        InfiniteScrollModule
    ],
    declarations: [NotificationComponent],
    exports: [NotificationComponent]
})
export class NotificationModule { }
