import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddBalanceComponent } from './add-balance.component';
import { BalanceRoutingModule } from './add-balance-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import {MatTabsModule} from '@angular/material/tabs';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';

import { AppPipesModule } from '../pipes/app.pipes.module';

@NgModule({
    imports: [
        CommonModule,
        BalanceRoutingModule,
        TranslateModule,
        MatTabsModule,
        InfiniteScrollModule,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        AppPipesModule
    ],
    declarations: [AddBalanceComponent],
    exports: [AddBalanceComponent]
})
export class BalanceModule { }
