import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LandingLayoutComponent } from '../layout/landing-layout/landing-layout.component';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  {
    path: '',
    component: LandingLayoutComponent,
    children: [
      { path: '',
        component: AuthComponent,
        children: [
          { path: '', component: LoginComponent }
        ]
      }
    ]
  },
  {
    path: 'login',
    component: LandingLayoutComponent,
    children: [
      { path: '',
        component: AuthComponent,
        children: [
          { path: '', component: LoginComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
