import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Pipe({
    name: 'dateFormatPipe',
})
export class dateFormatPipe implements PipeTransform {
    transform(value: string) {
        var datePipe = new DatePipe("en-US");
        value = datePipe.transform(value, 'MMM dd, EEE hh:mm aa');
        return value;
    }
}

@Pipe({
    name: 'onlyDateFormatPipe',
})
export class onlyDateFormatPipe implements PipeTransform {
    transform(value: string) {
        var datePipe = new DatePipe("en-US");
        value = datePipe.transform(value, 'MMM dd, yyyy');
        return value;
    }
}

@Pipe({
    name: 'timeFormatPipe',
})
export class timeFormatPipe implements PipeTransform {
    transform(value: string) {
        var datePipe = new DatePipe("en-US");
        value = datePipe.transform(value, 'hh:mm aa');
        return value;
    }
}

@Pipe({
    name: 'noOfDays',
})
export class noOfDays implements PipeTransform {
    transform(value: string) {
        return moment.duration(value).days();
    }
}

@Pipe({
    name: 'noOfYears',
})
export class noOfYears implements PipeTransform {
    transform(value: string) {
        var datePipe = new DatePipe("en-US");
        value = datePipe.transform(value, 'M/d/y');
        return moment(new Date()).diff(moment(value, "MM/DD/YYYY"), 'years', false);
    }
}

// @Pipe({
//     name: 'noOfHours',
// })
// export class noOfHours implements PipeTransform {
//     transform(value: string) {
//         return moment.duration(value).hours();
//     }
// }

@Pipe({
    name: 'dateTimer',
})
export class dateTimer implements PipeTransform {
    transform(value: string) {
        var duration = moment.duration(value);
        var formatValue = duration.hours() + ":" + duration.minutes() + ":" + duration.seconds()
        return formatValue;
    }
}
