import { Component, Injectable, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

import { SubscriptionLike } from 'rxjs';

import { AlertService } from '../../_services/alert.services';
import { StorageService } from '../../_services/storage.service';
import { UserService } from '../../_services/user.service';
import { EncryptService } from '../../_services/encrypt.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { User } from '../../models/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../auth.component.scss', './login.component.scss']
})
@Injectable({ providedIn: 'root' })
export class LoginComponent implements OnInit, OnDestroy {
  subscription: SubscriptionLike;
  user: User;
  submitted = false;
  loginForm: UntypedFormGroup;
  loading = false;
  returnUrl: string;
  isApp = window["isThisApp"];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private encryptService: EncryptService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private storage: StorageService,
    private userService: UserService
  ) {
    // redirect to lobby if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate([`${this.storage.getLocalStorage('selectedSport').sports_name.toLowerCase()}` + '/lobby']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(3)]],
      remember: [false]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/lobby';
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  get login() { return this.loginForm.controls; }

  onLoginSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    let loginData: any = {};
    loginData.email = this.login.email.value;
    // loginData.password = this.encryptService.encrypt(this.login.password.value);
    loginData.password = this.login.password.value;
    loginData.device_type = 3;
    loginData.remember = this.login.remember.value;

    this.subscription = this.authenticationService.login(loginData)
      .pipe(first())
      .subscribe(
        data => {
          this.storage.setLocalStorage('selectedSport', 'teenPatti');
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.alertService.error(error.user_name ? error.user_name : error.password ? error.password : '');
          this.loading = false;
        });
  }

  showPassword() {
    let x = (<HTMLInputElement>document.getElementById("password"));
    if (x.type === "password") {
      document.getElementById("fa-eye-slash").style.display = "block";
      document.getElementById("fa-eye").style.display = "none";
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
  showPasswordAgain() {
    let x = (<HTMLInputElement>document.getElementById("password"));
    if (x.type === "text") {
      x.type = "password";
      document.getElementById("fa-eye-slash").style.display = "none";
      document.getElementById("fa-eye").style.display = "block";
    }
  }

}
