import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserBalanceComponent } from './user-balance.component';
import { AppPipesModule } from '../../pipes/app.pipes.module';


@NgModule({
    imports: [
        CommonModule,
        AppPipesModule
    ],
    declarations: [
        UserBalanceComponent
    ],
    exports: [UserBalanceComponent]
})
export class UserBalanceModule { }