import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { StorageService } from './storage.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(
        private router: Router,
        private http: HttpClient,
        private userService: UserService,
        private storage: StorageService
        ) {
        this.currentUserSubject = new BehaviorSubject<User>(this.storage.getLocalStorage('ngStorage-user'));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    signup(data: any) {
        return this.http.post<any>(`${environment.apiUrl}user/auth/signup`, data )
            .pipe(map(user => {
                this.storage.setStorageType(data.remember);
                this.storage.setLocalStorage('ngStorage-user', user.data);
                this.currentUserSubject.next(user.data);
                return user.data;
            }));
    }

    login(data: any) {
        return this.http.post<any>(`${environment.apiUrl}user/auth/login`, data )
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                this.storage.setStorageType(data.remember);
                this.storage.setLocalStorage('ngStorage-user', user.data);
                this.currentUserSubject.next(user.data);
                return user;
            }));
    }

    logout(session_key: string = this.storage.getLocalStorage('ngStorage-user').session_key) {
        return this.http.post<any>(`${environment.apiUrl}user/auth/logout`, { session_key })
            .pipe(map( () => {
                    // remove user from local storage and set current user to null
                    this.storage.clearAllLocalStorage();
                    this.currentUserSubject.next(null);
            }));
    }

    socialLogin(data: any, toPost: boolean) {
        if (toPost) {
            var user: any = {};
            switch (data.provider) {
                case "GOOGLE" : {
                    user.google_id = data.id;
                    user.google_access_token = data.idToken;
                    break;
                }
                case "FACEBOOK" : {
                    user.facebook_id = data.id;
                    user.facebook_access_token = data.authToken;
                    break;
                }
            }
            user.email = data.email;
            user.first_name = data.firstName;
            user.last_name = data.lastName;
            user.image = data.photoUrl;
            user.device_type = 3;
            
            // Login into app
            this.login(user)
                .pipe(first())
                .subscribe(
                    data => {
                        this.userService.headerDetails()
                            .pipe(first())
                            .subscribe(
                            data => {
                                // store sport details in local storage to make the url as per sport
                                this.storage.setLocalStorage('selectedSport', data.data.sport[0]);
                                this.router.navigate([`${data.data.sport[0].sports_name.toLowerCase()}` + '/lobby']);
                            },
                            error => {
                            });
                    },
                    error => {
                    });
        }
    }
}