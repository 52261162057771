import { Pipe, PipeTransform } from '@angular/core';

import { environment } from '../../environments/environment';

@Pipe({
    name: 'currencyFormat',
})
export class currencyFormat implements PipeTransform {
    transform(value: number) {
        return environment.currencySymbol + value;
    }
}

