import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainLayoutComponent } from '../layout/main-layout/main-layout.component';
import { LobbyComponent } from './lobby.component';
import { AuthGuard } from '../_helpers/auth.guard';


const routes: Routes = [
  {
    path: 'lobby',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: LobbyComponent }
    ]
  }
 
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LobbyRoutingModule { }