import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../_services/user.service';
import { SubscriptionLike } from 'rxjs';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {
  subscription: SubscriptionLike;
  offset: any;
  allNotification: any = [];
  emptyScreen = false;
  disableLoadMore = true;
  isLoadMore = false;
  posting = false;


  constructor(
    private router: Router,
    private userService: UserService
  ) {
    this.getNotification();


  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  getNotification() {
    if (this.posting) {
      return;
    }
    let notification = {
      offset: 0,
      sort_field: "added_date",
      sort_order: "DESC",
      limit: 20

    };
    this.posting = true;
    this.subscription = this.userService.getNotifications(notification)

      .subscribe((res) => {
        this.processNotification(res.data.notification_list);
        this.emptyScreen = (this.allNotification.length) ? false : true;
        if (res.data.notification_list.length && res.data.is_load_more) {
          this.disableLoadMore = false;
          this.isLoadMore = true;
        } else {
          this.disableLoadMore = true;
          this.isLoadMore = false;
        }
        if (notification.offset == 0) {
          this.readAll();
        }
        notification.offset = res.data.offset;
        this.posting = false;
      });
  }

  readAll() {
    this.subscription = this.userService.updateReadAll()
      .subscribe((res) => {

      })
  }

  /*
    This method is used to chnage the message that is received from server
   */
  processNotification(list) {
    for (var obj of list) { // List is Array so for-of loop
      var contentObj = JSON.parse(obj.content);
      obj.content = contentObj;

      obj.contest_id = contentObj.contest_id;
      obj.contest_unique_id = contentObj.contest_unique_id;
      obj.season_game_uid = contentObj.season_game_uid;

      for (var key in contentObj) { // contentObj is Obj so for-in loop
        obj.message = obj.message.replace('₹', '');
        obj.message = obj.message.replace('{{' + key + '}}', contentObj[key]);
      }
      this.allNotification.push(obj);
    }
  }


  onScroll() {
    if (!this.isLoadMore && !this.posting) {
      return;
    }
    this.getNotification();
  }


}
