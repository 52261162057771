import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { ChangePasswordComponent } from './change-password.component';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { changePasswordRoutingModule } from './change-password.routing.module';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        TranslateModule,
        changePasswordRoutingModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    declarations: [ChangePasswordComponent],
    exports: [ChangePasswordComponent]
})
export class changePasswordModule { }
