import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from '../_services/alert.services';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: any;

    constructor(private alertService: AlertService) { }

    ngOnInit() {
        this.subscription = this.alertService.getAlert()
            .subscribe(message => {
                switch (message && message.type) {
                    case 'success':
                        message.cssClass = 'alert alert-success alert-slide';
                        break;
                    case 'error':
                        message.cssClass = 'alert alert-danger alert-slide';
                        break;
                }

                this.message = message;
                setTimeout(() => {
                  this.message = '';
                }, 4000);
            });


    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    close(){
      let elem: HTMLElement = document.getElementById('close-alert');
      elem.setAttribute("style", "display:none;");
    }

}
