import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LobbyService {

  constructor(private http: HttpClient) { }

  findObjPosition(data: any, key, val) {
    var result = data.map(function (x) {
      return x[key];
    }).indexOf(val);
    return result;
  }

  getLobbyMatches() {
    return this.http.post<any>(`${environment.apiUrl}server_conf.php`, {});
  }

  getSportLeagues(data: object) {
    return this.http.post<any>(`${environment.apiUrl}fantasy/lobby/get_sport_leagues`, data);
  }

  getSeasonDetails(data: object) {
    return this.http.post<any>(`${environment.apiUrl}fantasy/contest/get_season_details`, data);
  }

  getContestOfSeason(data: object) {
    return this.http.post<any>(`${environment.apiUrl}fantasy/lobby/get_contests_of_season`, data);
  }

  getContestDetails(data: object) {
    return this.http.post<any>(`${environment.apiUrl}fantasy/contest/get_contest_detail`, data);
  }

  getContestRank(data: object) {
    return this.http.post<any>(`${environment.apiUrl}fantasy/contest/get_contest_rank`, data);
  }

  getLeaderboardList	(data: object) {
    return this.http.post<any>(`${environment.apiUrl}fantasy/leaderboard/leaderboard_list`, data);
  }

  getLineupMasterData(data: object) {
    return this.http.post<any>(`${environment.apiUrl}fantasy/cricket_lineup/lineup/get_lineup_master_data`, data);
  }

  getAllRosters(data: object) {
    return this.http.post<any>(`${environment.apiUrl}fantasy/cricket_lineup/lineup/get_all_roster`, data);
  }

  getUserLineupList(data: object) {
    return this.http.post<any>(`${environment.apiUrl}fantasy/lobby/get_user_lineup_list`, data);
  }

  getUserLineupForSeason(data: object) {
    return this.http.post<any>(`${environment.apiUrl}fantasy/lobby/get_user_lineup_for_season`, data);
  }

  getLineupMasterId(data: object) {
    return this.http.post<any>(`${environment.apiUrl}fantasy/contest/get_lineup_by_master_id`, data);
  }

  checkEligibilityForContest(data: object) {
    return this.http.post<any>(`${environment.apiUrl}fantasy/contest/check_eligibility_for_contest`, data);
  }
}
