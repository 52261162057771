import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainLayoutComponent } from '../../layout/main-layout/main-layout.component';
import { ChangePasswordComponent } from './change-password.component';
import { AuthGuard } from '../../_helpers/auth.guard';


const routes: Routes = [
  {
    path: 'change-password',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ChangePasswordComponent }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class changePasswordRoutingModule { }
