import { Component, Renderer2, OnInit, OnDestroy, ViewEncapsulation, NgZone } from '@angular/core';
import { UserService } from '../_services/user.service';
import { StorageService } from '../_services/storage.service';

import { SubscriptionLike } from 'rxjs';

@Component({
  selector: 'app-add-balance',
  templateUrl: './add-balance.component.html',
  styleUrls: ['./add-balance.component.scss'],
  encapsulation: ViewEncapsulation.None

})

export class AddBalanceComponent implements OnInit, OnDestroy {
  subscription: SubscriptionLike;
  user_balance: any = [];
  total_balance: any;
  submitted = false;
  phone_no: string;
  amount: string;
  offset: number = 0;
  allTransiction: any = [];
  gameHistory: any = [];
  isLoadMore = false;
  disableLoadMore = true;
  posting = false;
  session_key = this.storage.getLocalStorage('ngStorage-user').session_key;

  constructor(
    private userService: UserService,
    private storage: StorageService,
  ) {

    let userBalanceData = {
      session_key: this.session_key
    }
    this.subscription = this.userService.getUserBalance(userBalanceData)
      .subscribe((res) => {
        this.user_balance = res.data.user_balance;
        let balance = res.data.user_balance;
        this.total_balance = this.addition(balance.bonus_amount, balance.real_amount, balance.winning_amount).toFixed(2);
      });

      this.getTransaction();
  }



  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getTransaction() {
    if (this.posting) {
      return;
    }
    let transictions = {
      session_key: this.session_key,
      limit: 20,
      offset: this.offset
    };
    this.posting = true;
    this.subscription = this.userService.getTransactionHistory(transictions)
      .subscribe((res) => {
        // this.allTransiction = this.allTransiction.concat(res.data.transaction_history);
        this.allTransiction = res.data.transaction_history;

        if (res.data.transaction_history.length && res.data.is_load_more) {
          this.disableLoadMore = false;
          this.isLoadMore = true;
        } else {
          this.disableLoadMore = true;
          this.isLoadMore = false;
        }

        this.offset = res.data.offset;
        this.posting = false;
      });
  }

  getGameHistory() {
    if (this.posting) {
      return;
    }
    let data = {
      session_key: this.session_key,
      current_page: 1,
      items_perpage: 10,
      total_items: 0
    };
    this.posting = true;
    this.subscription = this.userService.getGamenHistory(data)
      .subscribe((res) => {
        this.gameHistory = res.data.transaction_history;
        this.offset = res.data.offset;
        this.posting = false;
      });
  }



  addition(num1: number, num2: number, num3: number) {
    return +num1 + +num2 + +num3;
  }

  ngOnInit() {
    // this.getTransaction();
  }

  onScroll() {
    if (!this.isLoadMore && !this.posting) {
      return;
    }
    this.getTransaction();
  }

  financeTabChanged($event) {
    if($event.index == 0) {
      this.getTransaction();
    } else if($event.index == 1) {
      this.getGameHistory();
    }
  }

}