import { Component, OnInit, Input } from '@angular/core';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {
  @Input() endTime: any='';
  @Input() startTime: any;
  @Input() matchStatus: string;

  $counter;
  endTimer = this.endTime;

  ngOnInit() {
      this.$counter = interval(1000).pipe(
          map((x) => {
              return x;
          })
      ).subscribe(x => {
        const duration: any = moment.duration(this.endTime - x*1000);

        // let days = duration.days();
        let hours = duration.hours();
        let minutes = duration.minutes();
        let seconds = duration.seconds();
        
        // if(days >= 2) {
        //   this.time = days + " days left";
        // } else if (days == 1) {
        //   this.time = 24 + hours + "h left";
        // } else if (days < 1 && hours > 5) {
        //   this.time = hours + "h left";
        // } else if (hours <=5 && hours >= 1) {
        //   this.time = hours + "h " + this.formatTime(minutes) + "m left";
        // } else if (hours < 1) {
        //   this.time = this.formatTime(minutes) + "m " + this.formatTime(seconds) + "s left";
        // } else {
        //   this.time = "date error";
        // }
        
        if ( hours > 5) {
          this.endTimer = hours + "h left";
        } else if (hours <=5 && hours >= 1) {
          this.endTimer = hours + "h " + this.formatTime(minutes) + "m left";
        } else if (hours < 1) {
          this.endTimer = this.formatTime(minutes) + "m " + this.formatTime(seconds) + "s left";
        } else if (hours == 0 && minutes == 0 && seconds == 0) {
          this.endTimer = "00m 00s left";
        } else {
          this.endTimer = "date error";
        }

        if (hours == 0 && minutes == 0 && seconds == 0) {
          console.log("Timer ends");
          window.location.reload();
        }

      });
  }

  private formatTime(value: number) {
    return value <= 9 ? '0' + value : value;
  }

  
}
