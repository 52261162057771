import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SubscriptionLike } from 'rxjs';

import { Router } from '@angular/router';

import { UserService } from '../../_services/user.service';
import { first } from 'rxjs/operators';
import { AlertService } from '../../_services/alert.services';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  subscription: SubscriptionLike;
  changePasswordForm: UntypedFormGroup;
  submitted = false;
  loading = false;
  constructor(
    public fb: UntypedFormBuilder,
    private userService: UserService,
    private alertService: AlertService,
    private router: Router
  ) { }

  ngOnInit() {
    this.changePasswordForm = this.fb.group({
      old_password: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get change() { return this.changePasswordForm.controls; }

  onchangePassword(){
    this.submitted=true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    this.loading = true;
    let changePasswordData: any = {};
    changePasswordData.old_password = this.change.old_password.value;
    changePasswordData.password = this.change.password.value;
    this.subscription=this.userService.changePassword(changePasswordData)
      .pipe(first())
      .subscribe(
        res => {
          if (res.response_code == 200) {
            this.alertService.success(res.message);
            let vm = this;
            setTimeout(()=> {
              vm.submitted = false;
              vm.router.navigate(['/profile']);
            },3000);
          }
        },
        error => {
          if(!changePasswordData.old_password || !changePasswordData.password){
            this.alertService.error(error.password);
          }

          if(!changePasswordData.old_password || changePasswordData.password){
            this.alertService.error(error.old_password);
          }

        });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
