import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MediaMatcher } from "@angular/cdk/layout";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "gt-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  title = "Apne11";
  mobileQuery: MediaQueryList;
  // tslint:disable-next-line: variable-name
  _mobileQueryListener: () => void;

  constructor(
    private translate: TranslateService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private toast: MatSnackBar
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang("en");
    this.mobileQuery = media.matchMedia("(max-width: 600px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() { }

  useLanguage(language: string) {
    this.translate.use(language);
  }
}
