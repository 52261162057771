import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StorageService {
  boolean = false;
  constructor() { }

  setStorageType(name: any) {
    if (name === "" || name === false) {
      localStorage.setItem("storageType", "session");
    } else {
      localStorage.setItem("storageType", "local");
    }
  }

  setLocalStorage(name: string, value: any) {
    if (localStorage.getItem("storageType") === "local"){
      localStorage.setItem(name, JSON.stringify(value));
    } else if (localStorage.getItem("storageType") === "session"){
      sessionStorage.setItem(name, JSON.stringify(value));
    }
  }

  getLocalStorage(name: string) {
    if (localStorage.getItem("storageType") === "local"){
      return JSON.parse(localStorage.getItem(name));
    } else if (localStorage.getItem("storageType") === "session"){
      return JSON.parse(sessionStorage.getItem(name));
    }
  }

  clearLocalStorage(name: string) {
    localStorage.removeItem(name);
    sessionStorage.removeItem(name);
  }

  clearAllLocalStorage() {
    localStorage.clear();
    sessionStorage.clear();
  }
}
