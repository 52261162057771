import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTabsModule} from '@angular/material/tabs';
import { ProfileComponent } from './profile.component';
import { TranslateModule } from '@ngx-translate/core';

import { ProfileRoutingModule } from './profile-routing.module';
import { AppPipesModule } from '../../pipes/app.pipes.module';

@NgModule({
    imports: [
        CommonModule,
        ProfileRoutingModule,
        MatFormFieldModule,
        MatTabsModule,
        TranslateModule,
        AppPipesModule
    ],
    declarations: [ProfileComponent],
    exports: [ProfileComponent]
})
export class ProfileModule { }
