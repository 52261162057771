import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../_services/authentication.service';
import { StorageService } from '../_services/storage.service';
import { AlertService } from '../_services/alert.services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private authenticationService: AuthenticationService,
        private storage: StorageService,
        private alertService: AlertService
        ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                this.storage.clearAllLocalStorage();
                location.reload();
            }

            if (err.status === 500) {
                this.alertService.error(err.error.global_error);
            }
            
            const error = err.error.error || err.statusText;
            return throwError(error);
        }))
    }
}