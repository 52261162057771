import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatDialogModule } from "@angular/material/dialog";
import {MatTabsModule} from '@angular/material/tabs';
import { MatSidenavModule } from  '@angular/material/sidenav';
import { TranslateModule } from '@ngx-translate/core';

import { LobbyRoutingModule } from './lobby-routing.module';
import { LobbyComponent } from './lobby.component';
import { UserBalanceModule } from '../finance/user-balance/user-balance.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        LobbyRoutingModule,
        UserBalanceModule,
        MatTabsModule,
        MatSidenavModule,
        TranslateModule
    ],
    declarations: [
        LobbyComponent
    ]
})
export class LobbyModule { }
