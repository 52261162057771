import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { HeaderBeforeLoginComponent} from './header-before-login/header-before-login.component';
import { FooterBeforeComponent} from './footer-before/footer-before.component';


@NgModule({
    imports: [
        CommonModule,
        AuthRoutingModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        BrowserAnimationsModule
    ],
    declarations: [
        AuthComponent,
        LoginComponent,
        HeaderBeforeLoginComponent,
        FooterBeforeComponent
    ]
})
export class AuthModule { }
