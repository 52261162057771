import { Component, OnInit, OnDestroy ,ViewEncapsulation} from '@angular/core';
import { UserService } from '../../_services/user.service';
import { AlertService } from '../../_services/alert.services';
import { SubscriptionLike } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ProfileComponent implements OnInit ,OnDestroy {
  subscription: SubscriptionLike;
  profiledata:any =[];
  member_since:Date;
  profileBankDetails:any;
  url: string;
  wurl: string;
  profileimage?: File;

  constructor(
    private alertService: AlertService,
    private userService: UserService,
  ) {
    this.subscription=this.userService.getUserProfile()
      .subscribe((data)=>{
        this.profiledata = data.data.user_profile;
        this.member_since = new Date(data.data.user_profile.added_date);
        this.profileBankDetails = data.data.user_bank_detail;
        this.url = "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.apne11.com%2Fsignup%2F%3Freferral_code%3D" + this.profiledata.referral_code;
        this.wurl = "https://wa.me/?text=https://www.apne11.com/signup/?referral_code=" + this.profiledata.referral_code;
      });

  }

  ngOnInit() {

  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  openProfileDialog() {
    document.getElementById('profileImage').click();
  }

  uploadProfile(files: FileList) {
    if (files) {
      var fileType = files[0].type;
      if (fileType.toLowerCase() == 'image/png' || fileType.toLowerCase() == 'image/jpg' || fileType.toLowerCase() == 'image/jpeg') {
        let profileData: FormData = new FormData();
        profileData.append('userfile', files.item(0), files.item(0).name);
        this.subscription = this.userService.updateProfileImage(profileData)
          .subscribe((res) => {
            this.profileimage = res.data.image_path;
            this.alertService.success('Profile Image Uploaded Successfully', true);
          })
      } else {
        this.alertService.error('only .png, .jpg and .jpeg format accepted.');
      }
    }
  }

}
