import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient) { }

  register(user: User) {
    return this.http.post(`${environment.apiUrl}user/auth/signup`, user);
  }

  forgotPassword(email: string) {
    return this.http.post<any>(`${environment.apiUrl}user/auth/forgot_password`, email);
  }

  forgotPasswordValidate(keyData: any) {
    return this.http.post<any>(`${environment.apiUrl}user/auth/forgot_password_validate_code`, keyData);
  }

  activateAccount(keyData: any) {
    return this.http.post<any>(`${environment.apiUrl}user/auth/activate_account`, keyData);
  }

  changePassword(data:any){
    return this.http.post<any>(`${environment.apiUrl}user/my_profile/change_password`, data);
  }

  headerDetails() {
    return this.http.post<any>(`${environment.apiUrl}user/my_profile/header_detail`, {});
  }

  getUserBalance(data: object) {
    return this.http.post<any>(`${environment.apiUrl}user/finance/get_user_balance`, data);
  }
  getUserProfile() {
    return this.http.post<any>(`${environment.apiUrl}user/my_profile/get_my_profile`, {});
  }


  getStateList(data: object) {
    return this.http.post<any>(`${environment.apiUrl}user/my_profile/get_state_list`, data);
  }
  getCountryList() {
    return this.http.post<any>(`${environment.apiUrl}user/my_profile/get_country_list`, {});
  }

  getNotifications(data: object) {
    return this.http.post<any>(`${environment.apiUrl}user/notification/get_notification`, data);
  }

  updateReadAll() {
    return this.http.get<any>(`${environment.apiUrl}user/notification/update_read_all`, {});
  }
  getReferalData(){
    return this.http.post<any>(`${environment.apiUrl}user/referral/get_referral_data`, {});
  }

  updateProfile(data: any) {
    return this.http.post<any>(`${environment.apiUrl}user/my_profile/update_profile`, data);
  }

  updateProfileImage(files) {
    return this.http.post<any>(`${environment.apiUrl}user/my_profile/do_upload`, files);
  }
  getTransactionHistory(data :object){
    return this.http.post<any>(`${environment.apiUrl}user/finance/get_transaction_history`, data);
  }
  getGamenHistory(data :object){
    return this.http.post<any>(`${environment.apiUrl}user/my_profile/game_history`, data);
  }
  withDrawlAmount(data :object){
    return this.http.post<any>(`${environment.apiUrl}user/finance/withdraw`, data);
  }
  getReferal(data: object){
    return this.http.post<any>(`${environment.apiUrl}fantasy/referral/get_referral`, data);
  }
  sendReferal(data: object){
    return this.http.post<any>(`${environment.apiUrl}user/referral/send_referral`, data);
  }
  contactUs(data: object){
    return this.http.post<any>(`${environment.apiUrl}user/common/contact_us`, data);
  }
  sendotp(data:any){
    return this.http.post<any>(`${environment.apiUrl}user/auth/resend_otp`,data);
  }
  validateotp(data:any){
    return this.http.post<any>(`${environment.apiUrl}user/auth/validate_otp`,data);
  }
  resetpassword(data:any){
    return this.http.post<any>(`${environment.apiUrl}user/auth/change_password`,data);
  }
  sendInvite(data:any){
    return this.http.post<any>(`${environment.apiUrl}user/invite/send_invite`,data);
  }
}
