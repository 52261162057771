import { NgModule } from '@angular/core';
import { dateFormatPipe, onlyDateFormatPipe, timeFormatPipe, noOfDays, noOfYears, dateTimer } from './date.pipe';
import { currencyFormat } from './number.pipe';
import { safe } from './html.pipe';

@NgModule({
  imports: [],
  declarations: [
    dateFormatPipe,
    onlyDateFormatPipe,
    timeFormatPipe,
    noOfDays,
    noOfYears,
    dateTimer,
    currencyFormat,
    safe
  ],
  exports: [
    dateFormatPipe,
    onlyDateFormatPipe,
    timeFormatPipe,
    noOfDays,
    noOfYears,
    dateTimer,
    currencyFormat,
    safe
  ]
})
export class AppPipesModule {}