import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-balance',
  templateUrl: './user-balance.component.html',
  styleUrls: ['./user-balance.component.scss']
})
export class UserBalanceComponent {

  @Input() balance: any;
  
}

